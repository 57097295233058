<template>
  <div>
    <h1 class="title">企业版试用</h1>
    <!-- <el-button type="primary" @click="toTry">企业版试用</el-button> -->
    <markdown :value="proDetail&&proDetail.trialAndDemoH"></markdown>
    <!-- <h2 class="subtitle">在线体验</h2>
    <ul>
      <li>环境地址：
        <el-link href="https://demo.metersphere.com/" type="primary" target="_blank">
          https://demo.metersphere.com/</el-link>
      </li>
      <li>用户名：demo</li>
      <li>密码：P@ssw0rd123..</li>
    </ul> -->
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Enterprisetrial",
  computed: {
    ...mapGetters(["proDetail"]),
  },
  methods: {
    toTry() {
      window.open(this.proDetail && this.proDetail.EnterpriseTrial);
    },
  },
};
</script>

<style scoped lang="scss">
.subtitle {
  margin: 20px 0 10px;
  font-size: 20px;
}
</style>
