<template>
  <div>
    <h1 class="title">在线安装</h1>
    <div class="markdown-body">
      <markdown :value="proDetail&&proDetail.quickStartH"></markdown>
      <!-- <p>仅需两步快速安装 JumpServer：</p>
      <ol>
        <li>准备一台 2核4G （最低）且可以访问互联网的 64 位 Linux 主机；</li>
        <li>以 root 用户执行如下命令一键安装 JumpServer。</li>
      </ol>
      <div class="highlight">
        <pre>curl -sSL https://github.com/jumpserver/jumpserver/releases/download/v2.11.1/quick_start.sh <span class="pl-k">|</span> bash</pre>
      </div> -->

    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GetStarted",
  computed: {
    ...mapGetters(["proDetail"]),
  },
};
</script>

<style lang="scss" scoped>
// .highlight {
//   background: #f6f8fa;
//   padding: 2px 15px;
// }
</style>
