<template>
  <div>
    <h1 class="title">产品文档</h1>
    <markdown :value="proDetail&&proDetail.userDocumentationH"></markdown>
    <!-- <el-link class="link" href="https://jumpserver.readthedocs.io/zh/master/" target="_blank"
      type="primary" icon="el-icon-right">安装使用指南</el-link> -->
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Documentation",
  computed: {
    ...mapGetters(["proDetail"]),
  },
};
</script>

<style scoped lang="scss">
</style>