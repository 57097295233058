<template>
  <div class="product-detail">
    <el-skeleton :loading="loading" animated>
      <template>
        <component :is="nowComponent" style="margin-top:20px" />
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import { detailProduct } from "@/api/products";
import Documentation from "./components/Documentation";
import Getstarted from "./components/GetStarted";
import Information from "./components/Information";
import Enterprisetrial from "./components/EnterpriseTrial";
import Downloads from "./components/Downloads";
import Techexchange from "./components/TechExchange";
import { mapGetters } from "vuex";
export default {
  name: "ProductDetail",
  computed: {
    ...mapGetters(["activeTab"]),
  },
  components: {
    Documentation,
    Getstarted,
    Information,
    Enterprisetrial,
    Downloads,
    Techexchange
  },
  watch: {
    activeTab: {
      immediate: true,
      handler: function (newValue) {
        this.nowComponent = newValue;
      },
    },
  },
  data() {
    return {
      loading: false,
      nowComponent: this.$route.params.tab,
    };
  },
  destroyed() {
    window.history.pushState(null, null, document.URL);
  },
  created() {
    this.getProDetail(this.$route.params.id);
  },
  methods: {
    getProDetail(id) {
      this.loading =true
      detailProduct(id).then((response) => {
        this.$store.commit("products/PRO_DETAIL", response);
        this.loading = false
      });
    },
  },
};
</script>
<style lang="scss">
.product-detail {
  line-height: 25px;
  position: relative;
  .product-nav {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    width: 100%;
    // left: 50%;
    // transform: translateX(-50%);
  }

  .title {
    font-size: 22px;
    padding: 10px 0 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ebedef;
  }

  .link {
    font-size: 18px;
  }
}
</style>
