<template>
  <div class="information">
    <h1 class="title">社区版下载</h1>
    <div class="release" v-if="versionList && versionList.length>0">
      <el-row>
        <el-col :span="4" class="left">
          <p>
            <span class="current-version">
              <i class="iconfont icon-biaoqian"></i>
              <b v-if="currentReleaseInfo">{{currentReleaseInfo.version}}</b></span>
            <span v-if="islatestRelease" class="Latest-release">Latest</span>
          </p>
          <el-dropdown class="dropdown" @command="handleCommand">
            <el-button>
              其他版本<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="release-dropdown-menu">
              <el-dropdown-item :disabled="item.id===currentReleaseId"
                v-for="(item,index) in versionList" :key="index" :command="item.id">{{item.version}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="20" class="right">
          <div class="version-title">
            <span v-if="currentReleaseInfo">{{currentReleaseInfo.version}}</span>
            <el-tooltip v-if="currentReleaseInfo && currentReleaseInfo.resourceList.length>0"
              class="item" effect="dark" content="下载此版本" placement="right-start">
              <i class="icon el-icon-download" @click="downloadVersion"></i>
            </el-tooltip>

          </div>
          <p style="color:#666666"><i class="el-icon-date"></i>
            {{currentReleaseInfo.publishTime | dateformat('YYYY-MM-DD HH:mm:ss')}}</p>
          <div class="version-info">
            <markdown :value="currentReleaseInfo && currentReleaseInfo.releaseNoteH || ''">
            </markdown>
            <!-- <p><b>Bug修复 Bug Fixes</b></p>
            <ul>
              <li>fix: 修复获取<code>系统用户认证信息</code>失败的问题（影响：单独设置过资产账号的密码的用户登录资产会失败）</li>
              <li>fix: 修复<code>升级</code>问题（系统消息通知相关）</li>
              <li>fix: 修复<code>基础平台</code>等可以删除更新问题</li>
              <li>fix: 修复会话详情中不能播放录像的问题</li>
            </ul>
            <p><b>功能优化 Optimization</b></p>
            <ul>
              <li>perf: 优化<code>XRDP</code>终端的默认名称</li>
            </ul> -->
          </div>
        </el-col>
      </el-row>

    </div>
  </div>

</template>

<script>
import { listReleases, detailRelease } from "@/api/products";
import { mapGetters } from "vuex";
export default {
  name: "Downloads",
  data() {
    return {
      id: this.$route.params.id,
      currentReleaseId: "",
      currentReleaseInfo: null,
      versionList: [],
    };
  },
  computed: {
    ...mapGetters(["proDetail"]),
    islatestRelease() {
      return this.currentReleaseId == this.proDetail?.latestRelease;
    },
  },
  watch: {
    proDetail: {
      immediate: true,
      handler: function (val) {
        this.currentReleaseInfo = val?.latestReleaseInfo;
        this.currentReleaseId = val?.latestRelease;
      },
    },
  },
  created() {
    this.getListReleases();
  },
  methods: {
    getListReleases() {
      listReleases(this.id).then((response) => {
        this.versionList = response;
      });
    },
    handleCommand(command) {
      detailRelease(this.id, command).then((response) => {
        this.currentReleaseInfo = response;
      });
      this.currentReleaseId = command;
    },
    downloadVersion() {
      this.$router.push(`/download/${this.id}/${this.currentReleaseId}`);
      // const releaseId = this.currentReleaseInfo?.id;
      // this.$store.dispatch("products/asynDownloadResources", {
      //   releaseId,
      //   resourceId,
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.information {
  .release {
    // border-top: 1px solid #e1e4e8;
    margin: -15px 0 20px;

    .left {
      padding: 20px;
      text-align: right;
      .current-version {
        margin-right: 5px;
        font-size: 16px;
        .iconfont {
          margin-right: 5px;
          color: #22863a;
        }
      }
      .Latest-release {
        border: 1px solid #22863a;
        color: #22863a;
        border-radius: 10px;
        font-size: 12px;
        padding: 2px 5px;
      }
      // .dropdown {
      //   margin-top: 20px;
      // }
    }
    .right {
      padding: 20px;
      border-left: 1px solid #e1e4e8;
      .version-title {
        font-size: 24px;
        font-weight: bold;
        .icon {
          font-size: 24px;
          margin-left: 5px;
          color: #419756;
          cursor: pointer;
          &:hover {
            color: #25ab23;
          }
        }
      }
      // .version-info {
      //   margin-top: 10px;
      // }
    }
  }
}
</style>
