<template>
  <div class="information">
    <markdown :value="proDetail&&proDetail.userDescriptionH||''"></markdown>
    <!-- <h1 class="title">JumpServer 多云环境下更好用的堡垒机</h1>
    <div>
      <p>JumpServer 是全球首款开源的堡垒机，使用 GNU GPL v2.0 开源协议，是符合 4A 规范的运维安全审计系统。</p>
      <p>JumpServer 使用 Python / Django 为主进行开发，遵循 Web 2.0 规范，配备了业界领先的 Web Terminal 方案，交互界面美观、用户体验好。
      </p>
      <p>JumpServer 采纳分布式架构，支持多机房跨区域部署，支持横向扩展，无资产数量及并发限制。</p>
      <p>改变世界，从一点点开始。</p>
    </div> -->

  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Information",
  data() {
    return {
      // id: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters(["proDetail"]),

  },
  watch: {
    // proDetail: {
    //   immediate: true,
    //   handler: function (val) {
    //     this.currentReleaseInfo = val?.latestReleaseInfo;
    //     this.currentReleaseId = val?.latestRelease;
    //   },
    // },
  },
  created() {
  },
  methods: {
   
  },
};
</script>

<style scoped lang="scss">
.information {
  line-height: 30px;
}
</style>