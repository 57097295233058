<template>
  <div>
    <div class="markdown-body">
      <markdown :value="proDetail&&proDetail.techExchangeH"></markdown>
    </div>
    <h1 class="title">贡献者</h1>
    <div class="avatar-body" v-loading="loading">
      <ul>
        <li v-for="(item, index) in contributorsList" :key="index">
          <el-popover v-if="item.type==='User'" placement="top-start" width="400" trigger="hover">
            <div class="avatar-popover" v-loading="userLoading">
              <div class="avatar">
                <el-avatar :size="70" :src="item.avatar_url" slot="reference" @error="errorHandler">
                  <img src="@/assets/certificate/default.png" />
                </el-avatar>
              </div>
              <div class="info">
                <p><b>{{currentUser && currentUser.name}}</b><span
                    style="margin-left: 5px">{{item.login}}</span></p>
                <p>{{currentUser && currentUser.bio}}</p>
                <p v-if="currentUser && currentUser.location"><i
                    class="el-icon-location-outline"></i>{{currentUser && currentUser.location}}
                </p>
              </div>
              <div class="describe">
                <p><i class="el-icon-link"></i><a :href="item.html_url"
                    target="_blank">查看GitHub主页</a></p>
                <p style="margin-top:10px;"><i class="el-icon-view"></i><a
                    :href="getUrl(item.login)" target="_blank">查看贡献者证书</a></p>
              </div>
            </div>
            <el-avatar size="large" :src="item.avatar_url" @error="errorHandler" slot="reference"
              @click.native="getUser(item.login)" @mouseenter.native="getUser(item.login)"
              style="cursor: pointer;border: 1px solid #eaeefb;">
              <img src="@/assets/certificate/default.png" />
            </el-avatar>
          </el-popover>
          <el-avatar v-else-if="item.type==='Bot'" size="large" :src="item.avatar_url"
            @error="errorHandler" slot="reference"
            style="cursor: pointer;border: 1px solid #eaeefb;">
            <img src="@/assets/certificate/default.png" />
          </el-avatar>
        </li>
      </ul>
    </div>

  </div>

</template>

<script>
import { listContributors, getUserInfo } from "@/api/products";
import { mapGetters } from "vuex";

export default {
  name: "TechExchange",

  computed: {
    ...mapGetters(["proDetail"]),
  },
  data() {
    return {
      loading: false,
      userLoading: false,
      contributorsList: [],
      currentUser: {},
    };
  },
  created() {
    this.getListContributors(this.proDetail?.id);
  },
  methods: {
    getUrl(id) {
      const url =
        window.location.protocol +
        "//" +
        window.location.host +
        `/v1/${this.proDetail?.id}/contributors/${id}/certification.pdf`;
      return url;
    },
    getUser(login) {
      clearTimeout(this.timer);
      if (login !== this.currentUser?.login) {
        this.currentUser = {};
        this.userLoading = true;
        this.timer = setTimeout(() => {
          getUserInfo(this.proDetail?.id, login)
            .then((res) => {
              this.currentUser = res;
              this.userLoading = false;
            })
            .catch(() => {
              this.userLoading = false;
            });
        }, 150);
      }
    },
    getListContributors(id) {
      this.loading = true;
      listContributors(id).then((response) => {
        this.contributorsList = response;
        this.loading = false;
      });
    },
    errorHandler() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-body {
  li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;
  }
}
.avatar-popover {
  padding: 5px;
  p {
    margin: 0;
    padding: 0;
    word-break: keep-all;
    word-wrap: break-word;
  }
  .avatar {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
  }
  .info {
    display: inline-block;
    vertical-align: top;
    line-height: 25px;
    width: 70%;
  }
  i {
    margin-right: 5px;
  }
  .describe {
    border-top: 1px solid #d8dee4;
    padding-top: 10px;
    margin-top: 15px;
    a:hover {
      color: #021c3a;
    }
  }
}
</style>
